$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  xmd: 992px,
  lg: 1200px,
  xl: 1440px,
  xxl: 1600px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  xmd: 960px,
  lg: 1140px,
  xl: 1320px,
  xxl: 1560px,
);

// typography
$enable-responsive-font-sizes: true;
$font-family-base: "Gotham A", "Gotham B";
$font-weight-base: 400;
$headings-font-weight: 300;
$display1-weight: $headings-font-weight;
$display2-weight: $headings-font-weight;
$display3-weight: $headings-font-weight;
$display4-weight: $headings-font-weight;

// colors
$brand-dark-gray: #2d333b;
$brand-red: #093f6a;
$brand-green: #616c6b;
$brand-lt-gray: #c1c5c9;
$brand-dark-brown: #4a4038;
$brand-taupe: #998a7e;
$brand-tan: #093f6a;

$primary: $brand-red;
$secondary: $brand-dark-gray;
$link-color: #093f6a;

$navbar-dark-color: #ffffff;
$dropdown-link-color: #ffffff;

// breadcrumbs
$breadcrumb-bg: transparent;
$breadcrumb-padding-x: 0;
$breadcrumb-padding-y: 0;

// pagination
$pagination-disabled-color: #ffffff;
$pagination-disabled-bg: $brand-red;
$pagination-focus-box-shadow: 0 0 0 0.2rem rgba($secondary, 0.25);


// button arrows
$white-arrow: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 282.19 564.38'%3e%3cpath fill='#ffffff' d='M169.31 564.38H0l112.87-282.2L0 0h169.31l112.88 282.18z'/%3e%3c/svg%3e");
$red-arrow: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 282.19 564.38'%3e%3cpath fill='#093f6a' d='M169.31 564.38H0l112.87-282.2L0 0h169.31l112.88 282.18z'/%3e%3c/svg%3e");

// dropdown
$dropdown-bg: $brand-dark-gray;
$dropdown-min-width: 12rem;
$dropdown-border-radius: 0;
$dropdown-item-padding-x: 1rem;
$dropdown-link-hover-bg: lighten($brand-dark-gray, 5);
$dropdown-link-hover-color: rgba(255,255, 255, 0.75);

// spacing
$navbar-nav-link-padding-x: 1rem;