.qsi-site-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1001;
  @include media-breakpoint-down(md) {
    background: rgba($primary,.9);
  }
  @include media-breakpoint-up(xmd) {
    position: absolute;
  }
  .navbar-toggler {
    z-index: 2;
  }
  .home-link {
    outline-color: $white;
  }
  .nav-link {
    &.active {
      position: relative;
      @include media-breakpoint-down(md) {
        &:after {
          content: '';
          display: inline-block;
          width: 12px;
          height: 12px;
          background-color: $brand-tan;
          border: 1px solid $brand-dark-gray;
          position: relative;
          border-radius: 9999rem;
          margin-left: .75rem;
        }
      }
      @include media-breakpoint-up(xmd) {
        &:after {
          content: '';
          position: absolute;
          background: $brand-tan;
          height: 3px;
          left: 1rem;
          right: 0;
          bottom: 0;
          width: calc(100% - 1.875rem);
        }
      }
    }
    &:focus {
      outline-color: $white;
    }
  }
  @include media-breakpoint-down(md) {
    .navbar {
      align-items: flex-start;
      .navbar-nav {
        border-top: 1px solid $brand-tan;
        padding-top: 1rem;
        margin-bottom: 1rem;
      }
    }
    .navbar-collapse {
      position: absolute;
      background: $brand-dark-gray;
      top: 91px;
      left: -5px;
      width: calc(100% + 10px);
      box-shadow: 0 50px 100px -20px rgba(50, 50, 93, .25), 0 30px 60px -30px rgba(0, 0, 0, .3), 0 -18px 60px -10px rgba(0, 0, 0, .025);
      z-index: 1;
      transform: scale(.95);
      transform-origin: top center;
      opacity: 0;
      will-change: transform, opacity;
      transition-property: transform, opacity;
      transition-duration: .25s;
      pointer-events: none;
      padding: 1.25rem;
      &.expanded {
        transform: none;
        opacity: 1;
        pointer-events: auto;
        overflow: hidden;
      }
    }
  }
}
.qsi-category-nav {
  a {
    &:hover {
      background-color: $light;
      color: $brand-dark-gray;
    }
    &.active {
      &:after {
        content:'';
        display: inline-block;
        width: 12px;
        height: 12px;
        background-color: $brand-tan;
        border: 1px solid $brand-dark-gray;
        position: relative;
        border-radius: 9999rem;
        margin-left: .75rem;
      }
    }
    &:focus {
      outline: 2px solid $brand-dark-gray;
      outline-offset: 2px;
    }
  }
}
#mobile-header-estimate-btn {
  @include media-breakpoint-down(sm) {
    display: block;
    width: 100%;
  }
}