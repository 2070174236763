// containers
.row-screen-height {
  height: 100vh;
  min-height: 36rem;
}
.row-min-height {
  height: 90vh;
  min-height: 36rem;
}
.row-min-height-narrow {
  height: 65vh;
  min-height: 24rem;
}
.row-min-height-narrower {
  height: 30vh;
  min-height: 20rem;
}
.inner-max-width {
  max-width: 48rem;
  width: 100%;
}
.copy-inner-max-width {
  max-width: 60rem;
  width: 100%;
}
// Header images & Text Overlays
.header-bg-img,
.page-header-bg-img {
  &:before {
    content: '';
    position: absolute;
    background: linear-gradient(to bottom, rgba($brand-dark-gray,.8), rgba($brand-dark-gray,0));
    top: 0;
    left: 0;
    right: 0;
    height: 20vh;
    width: 100%;
    z-index: 2;
  }
  &:after {
    content: "";
    position: absolute;
    background: rgba($brand-dark-gray,.8);
    background: -webkit-gradient(to right, from(rgba($brand-dark-gray,.8)), to(rgba($brand-dark-gray,0)));
    background: linear-gradient(to right, rgba($brand-dark-gray,.8), rgba($brand-dark-gray,0));
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 80vw;
    z-index: 1;
  }
}
.header-bg-img,
.page-header-bg-img {
  .bg-text-overlay {
    position: relative;
    z-index: 2;
  }
}
.header-bg-img {
  .bg-text-overlay {
    margin-bottom: 10vh;
  }
}

// home promo
.qsi-promo-bg-img {
  position: relative;
  padding-bottom: 62.5%;
  @include media-breakpoint-up(md) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    padding-bottom: 0;
  }
}
.promo-inner-wrapper {
  @include media-breakpoint-up(md) {
    background-color: $secondary;
    &:after {
      content: "";
      position: absolute;
      background: -webkit-gradient(linear, left bottom, left top, from(rgba(45, 51, 59, 0.8)), color-stop(rgba(45, 51, 59, 0.65)), to(rgba(45, 51, 59, 0)));
      background: linear-gradient(to top, rgba(45, 51, 59, 0.8), rgba(45, 51, 59, 0.65), rgba(45, 51, 59, 0));
      bottom: 0;
      left: 0;
      height: 40vh;
      width: 100%;
      z-index: 1;
    }
  }
}
.promo-copy {
  position: relative;
  z-index: 3;
  @include media-breakpoint-up(md) {
    color: $white;
  }
}

// featured content over text
.qsi-featured-section-bg-img {
  position: relative;
  padding-bottom: 62.5%;
  @include media-breakpoint-up(sm) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    padding-bottom: 0;
  }
}
.featured-bg-overlay {
  background: rgba($brand-dark-gray, .85);
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%;
  height: 100%;
  z-index: 2;
}
.qsi-featured-section-content {
  z-index: 2;
  @include media-breakpoint-down(xs) {
    background-color: $brand-dark-gray;
  }
}
.qsi-home-featured-section {
  &:nth-child(2n+1) {
    .featured-bg-overlay {
      right:0;
    }
    @include media-breakpoint-up(sm) {
      .qsi-featured-section-content {
        margin-left: 50%;
      }
    }
  }
  &:nth-child(2n) {
    .featured-bg-overlay {
      left:0;
    }
  }
}

// tabs with bg image
.qsi-tab-wrapper {
  .qsi-tab-link {
    color: $white;
    font-weight: 500;
    letter-spacing: .0625rem;
    position: relative;
    text-shadow: 0 2px 5px $brand-dark-gray;
    transform: translateY(0px);
    transition: transform 0.15s linear;
    &:hover {
      transform: translateY(-5px);
    }
    &:focus {
      outline: 2px solid $brand-tan;
      outline-offset: 2px;
    }
    &.active {
      background-color: transparent;
      &:after {
        content: '';
        position: absolute;
        background: $brand-tan;
        left: 0;
        right: 0;
        bottom: 0;
        height: 3px;
      }
    }
  }
  @include media-breakpoint-up(md) {
    &:before {
      content: '';
      position: absolute;
      background: linear-gradient(to bottom, rgba($brand-dark-gray, .95), rgba($brand-dark-gray, .6), rgba($brand-dark-gray, 0));
      top:0;
      left:0;
      height: 175px;
      width: 100%;
      z-index: -1;
    }
  }
}
.qsi-tab-pane-with-bg {
  @include media-breakpoint-up(md) {
    &:after {
      content: '';
      position: absolute;
      background: linear-gradient(to top, rgba($brand-dark-gray, .8), rgba($brand-dark-gray, .65), rgba($brand-dark-gray, 0));
      bottom:0;
      left:0;
      height: 40vh;
      width: 100%;
      z-index: 1;
    }
  }
}
.qsi-tab-container-with-bg {
  @media screen and (min-width: 768px) and (max-width: 1599px) {
    width: 100%;
    max-width: none;
  }
}
.qsi-tab-pane-with-bg {
  &.tab-pane {
    @include media-breakpoint-down(sm) {
      display: block;
      opacity: 1;
    }
  }
}
.qsi-tabpanel-bg-img {
  position: relative;
  padding-bottom: 62.5%;
  @include media-breakpoint-up(md) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    padding-bottom: 0;
    z-index: 1;
  }
}
.qsi-tab-pane-content-with-bg {
  z-index: 2;
  @include media-breakpoint-up(md) {
    color: #ffffff;
    height: 75vh;
    min-height: 24rem;
  }
}

//content blocks
.cb-listModule {
  .list-module-copy {
    ul {
      > li {
        @extend .mb-2;
      }
      @extend .pl-3;
      list-style: disc;
    }
  }
}
.cb-testimonial {
  .qsi-testimonial-img {
    width: 325px;
    height: 325px;
  }
}
.cb-copy,
.cb-accordion {
  figcaption {
    margin-top: 0.5rem;
    @extend .figure-caption;
  }
  .copy-figure-left,
  .copy-figure-right {
    @extend .my-3;
    @include media-breakpoint-up(md) {
      width: 40%;
    }
  }
  .copy-figure-left {
    @include media-breakpoint-up(md) {
      float: left;
      margin-right: 2rem;
    }
  }
  .copy-figure-right {
    @include media-breakpoint-up(md) {
      float: right;
      margin-left: 2rem;
    }
  }
}
.qsi-accordion-button {
  margin-left: 0.5rem;
  &:before {
    position: absolute;
    content: '';
    background-image: escape-svg($red-arrow);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% auto;
    left: -0.5rem;
    top: 0.75rem;
    width: 6px;
    height: 15px;
    transform: rotate(0deg);
    transition: transform .15s ease;
  }
  &[aria-expanded='true'] {
    &:before {
      transform: rotate(90deg);
    }
  }
}
// resources
.resource-card-link {
  text-decoration: none;
  &:hover {
    text-decoration: none;
    .card {
      border:1px solid $brand-dark-gray;
    }
  }
  &:focus {
    text-decoration: none;
    outline:3px solid $brand-dark-gray;
    outline-offset: 5px;
  }
}
@include media-breakpoint-up(xmd) {
  .qsi-resource-card {
    flex-direction: row;
    .resource-img {
      width: 33.333333%;
    }
    .resource-card-copy {
      width: 66.666667%;
    }
  }
}
// Sales Portal
.qsi-sales-dropdown-toggle {
  color: $white;
  padding: .25rem;
  svg {
    fill: $white;
    width: 40px;
    height: 40px;
  }
  &:hover {
    background-color: transparent;
    svg {
      fill: lighten($brand-lt-gray, 15);
    }
  }
  &:focus {
    box-shadow: none!important;
    color: lighten($brand-lt-gray, 15)!important;
    background-color: transparent!important;
    outline: 2px solid $white;
    outline-offset: 3px;
  }
  &:active {
    box-shadow: none!important;
    color: lighten($brand-lt-gray, 15)!important;
    background-color: transparent!important;
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
}
.lead-btn {
  font-size: 80%;
  font-weight: 500;
}
.fig-table {
  max-height: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  table {
    max-width: 100%;
    th, td {
      white-space: nowrap;
    }
  }
}
select option[disabled] {
  display: none;
}
.building-type-check,
.timeline-type-check {
  padding-left: 0;
  flex-grow: 1;
  border: 1px solid $white;
  margin-left: -1px;
  .form-check-label {
    font-size: 80%;
    font-weight: 500;
    background-color: lighten($brand-lt-gray, 15);
    display: flex;
    justify-content: center;
    padding: 1.25rem 0.5rem;
    text-align: center;
    &:hover {
      background-color: $brand-tan;
    }
  }
  &:first-child {
    margin-left:0;
  }
  .building-type-check-input,
  .timeline-type-check-input {
    cursor: pointer;
    &:checked ~ label {
      background-color: $brand-tan;
      color: #ffffff;
    }
  }
  @include media-breakpoint-up(md) {
    &:first-child {
      .form-check-label {
        border-top-left-radius: 9999rem;
        border-bottom-left-radius: 9999rem;
      }
    }
    &:last-child {
      .form-check-label {
        border-top-right-radius: 9999rem;
        border-bottom-right-radius: 9999rem;
      }
    }
  }
}
.lead-clock,
.fa-icon-svg{
  width: 13px;
  height: 13px;
}
.loader {
  display: none;
  position: relative;
  top: -3px;
  border: 5px solid $brand-lt-gray;
  border-radius: 50%;
  border-top: 5px solid $brand-red;
  width: 25px;
  height: 25px;
  //-webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 800ms linear infinite;
  &.loading {
    display: block;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.event-date {
  width: 48px;
  background: #f4f3f0;
  text-align: center;
  border-top: 3px solid $brand-red;
  padding: .3em 0;
  padding-top: .35em;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  margin-right:10px;
}

.qsi-event-item {
  padding:10px;
  margin-bottom: 15px;
}
.qsi-project-col {
  min-height: 350px;
  .qsi-project-link {
    color: $white;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    padding: 1.25rem;
    z-index: 2;
    > div {
      transform: translateY(0px);
      transition: transform 0.15s linear;
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }
    &:hover {
      text-decoration: none;
      > div {
        transform: translateY(-10px);
      }
    }
    &:focus {
      outline: 3px solid $secondary;
      outline-offset: 5px;
    }
  }
  &:after {
    content: "";
    position: absolute;
    background: -webkit-gradient(linear, to top, from(rgba(45, 51, 59, 0.95)), color-stop(rgba(45, 51, 59, 0.6)), to(rgba(45, 51, 59, 0)));
    background: linear-gradient(to top, rgba(45, 51, 59, 0.95), rgba(45, 51, 59, 0.6), rgba(45, 51, 59, 0));
    bottom: 0;
    left: 15px;
    height: 175px;
    width: calc(100% - 30px);
    z-index: 1;
  }
  //.card-body {
  //  z-index: 2;
  //  &:after {
  //    content: "";
  //    position: absolute;
  //    background: -webkit-gradient(linear, to top, from(rgba(45, 51, 59, 0.95)), color-stop(rgba(45, 51, 59, 0.6)), to(rgba(45, 51, 59, 0)));
  //    background: linear-gradient(to top, rgba(45, 51, 59, 0.95), rgba(45, 51, 59, 0.6), rgba(45, 51, 59, 0));
  //    bottom: 0;
  //    left: 0;
  //    height: 175px;
  //    width: 100%;
  //    z-index: -1;
  //  }
  //}
}
.buildings-interstitial {
  &:before {
    content: "";
    position: absolute;
    background: rgba(45, 51, 59, 0.8);
    background: linear-gradient(to right, rgba(45, 51, 59, 0.8), rgba(45, 51, 59, 0));
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 80vw;
    z-index: 2;
  }
  &:after {
    content: "";
    position: absolute;
    background: rgba(45, 51, 59, 0.8);
    background: linear-gradient(to left, rgba(45, 51, 59, 0.6), rgba(45, 51, 59, 0));
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 80vw;
    z-index: 2;
  }
  .buildings-interstitial-inner {
    z-index: 3;
  }
}

.list-module-copy img{

    margin:0 auto; 
    display:block;
}


.carousel {
  background: #ffffff;
}

/* external css: flickity.css, fullscreen.css */

* { box-sizing: border-box; }

.carousel {
  background: #EEE;
}

.carousel-image {
  display: block;
  height: 450px;
  /* set min-width, allow images to set cell width */
  min-width: 150px;
  max-width: 100%;
  margin-right: 10px;
  /* vertically center */
  top: 50%;
  transform: translateY(-50%)
}

.carousel.is-fullscreen .carousel-image {
  height: auto;
  max-height: 100%;
}
.flickity-enabled.is-fullscreen {
  z-index: 3;
}

.bg-fade {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height:  100%;
    background: linear-gradient(to bottom, rgba($brand-lt-gray,.8), rgba($brand-lt-gray,.0));
    z-index: -1;
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height:  100%;
    z-index: -1;
  }
}
.bg-fade-up {

  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height:  100%;
    background: linear-gradient(to bottom, rgba($brand-lt-gray,0), rgba($brand-lt-gray,.8));
    z-index: -1;
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height:  100%;
    z-index: -1;
  }
}

.bg-tan-fade-down {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height:  25vh;
    background: linear-gradient(to bottom, rgba($brand-taupe,.3), rgba($brand-taupe,0));
    z-index: -1;
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height:  25vh;
    z-index: -1;
  }
}


.bg-tan-fade-up {

  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height:  100%;
    background: linear-gradient(to bottom, rgba($brand-taupe,0), rgba($brand-taupe,.3));
    z-index: -1;
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height:  100%;
    z-index: -1;
  }
}

.bg-dark{
  color:white !important;
  .btn-link{
    color:white !important;
  }
  .d-flex.flex-column.flex-xmd-row.align-items-center.ml-xmd-5.py-5.px-4.px-md-5.pl-xmd-0.border-secondary.border.shadow{
    background-color: #ffffff;
    color: #000000;
    border:1px solid black;
  }
  .qsi-testimonial-img {
    border:1px solid black;
  }

}